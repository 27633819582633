<template>
  <div class="home">
    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">

          <ul class="nav nav-admin">
            <li>
              <router-link to="/account/overview">
                <i class="material-icons">dashboard</i>
                <span>Übersicht</span>
              </router-link>
            </li>
            <li>
              <router-link to="/account/reservations">
                <i class="material-icons">today</i>
                <span>Reservierungen</span>
              </router-link>
            </li>
            <li>
              <router-link to="/account/payment_methods">
                <i class="material-icons">account_balance</i>
                <span>Bezahlmethoden</span>
              </router-link>
            </li>
            <li class="active">
              <router-link to="/account/settings">
                <i class="material-icons">settings</i>
                <span>Einstellungen</span>
              </router-link>
            </li>
          </ul>


        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li>Einstellungen</li>
            </ul>
          </header>

          <form @submit="update_user" class="form" method="post" style="margin-top:20px">

            <div class="form-wrap">
              <label for="company">Unternehmen</label>
              <input v-model="user.company" class="form-input" type="text" name="company" id="company">
            </div>

            <div class="row row-gutter-20">
              <div class="col-12">
                <div class="form-wrap">
                  <label for="first_name">Vorname</label>
                  <input v-model="user.first_name" class="form-input" type="text" name="first_name" id="first_name">
                </div>
              </div>
              <div class="col-12">
                <div class="form-wrap">
                  <label for="last_name">Nachname</label>
                  <input v-model="user.last_name" class="form-input" type="text" name="last_name" id="last_name">
                </div>
              </div>
            </div>

            <div class="row row-gutter-20">
              <div class="col-16">
                <div class="form-wrap">
                  <label for="street_name">Straße</label>
                  <input v-model="user.street_name" class="form-input" type="text" name="street_name" id="street_name">
                </div>
              </div>
              <div class="col-8">
                <div class="form-wrap">
                  <label for="street_number">Hausnummer</label>
                  <input v-model="user.street_number" class="form-input" type="text" name="street_number" id="street_number">
                </div>
              </div>
            </div>

            <div class="row row-gutter-20">
              <div class="col-8">
                <div class="form-wrap">
                  <label for="zip_code">Postleitzahl</label>
                  <input v-model="user.zip_code" class="form-input" type="text" name="zip_code" id="zip_code">
                </div>
              </div>
              <div class="col-16">
                <div class="form-wrap">
                  <label for="city">Stadt</label>
                  <input v-model="user.city" class="form-input" type="text" name="city" id="city">
                </div>
              </div>
            </div>

            <div class="form-wrap">
              <label for="phone_number">Telefonnummer</label>
              <input v-model="user.phone_number" class="form-input" type="text" name="phone_number" id="phone_number">
            </div>

            <div class="form-wrap">
              <label for="birth_date">Geburtsdatum</label>
              <input v-model="user.birth_date" class="form-input" type="text" name="birth_date" id="birth_date">
            </div>

            <p>
              <input type="submit" value="Speichern ›" class="button button-orange button-small">
            </p>


          </form>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'user',
  data () {
    return {
      user: {}
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/user', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.user = response.data.user;
      })
      .catch(error => {
        console.log(error);
      })

    },
    update_user: function (e) {
      e.preventDefault();

      axios.patch(process.env.VUE_APP_BASE_API+'/v1/user/', this.user, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert',
        });
        this.get_data();
      })
      .catch(error => {
        error.response.data.errors.forEach(val => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: val,
          });
        })
      })

    }
  },
  mounted () {
    this.get_data();
  }
}
</script>
